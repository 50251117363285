import React, { FC, HTMLAttributes } from 'react';
import { COLORS, TYPOGRAPHY } from '@/constants';
import styled from 'styled-components';

interface ContentCellProps extends HTMLAttributes<HTMLDivElement> {
  className?: string;
}

const ContentCell: FC<ContentCellProps> = ({ children, ...props }) => {
  return <Root {...props}>{children}</Root>;
};

const Root = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 10px;
  ${TYPOGRAPHY.captionMedium14};
  color: ${COLORS.black2};
  line-height: 18px;
  background-color: ${COLORS.lightGray9};
  border-radius: 12px;
`;

export default ContentCell;
