import { Container } from '@/components/Container';
import { Pagination } from '@/components/Pagination';
import { COLORS, TYPOGRAPHY } from '@/constants';
import { ArticleData } from '@/types';
import React, { FC } from 'react';
import styled from 'styled-components';
import { ArticleCard } from './components/ArticleCard';
import { Categories } from './components/Categories';

export interface CategoryData {
  locale: string;
  category: string;
}

interface ArticlesProps {
  title: string;
  articles: ArticleData[];
  categories: CategoryData[];
  paginatePath: string;
  paginationData: {
    humanPageNumber: number;
    nextPagePath: string;
    numberOfPages: number;
    previousPagePath: string;
    limit: number;
  };
}

const Articles: FC<ArticlesProps> = ({
  title,
  articles,
  categories,
  paginatePath,
  paginationData,
}) => {
  const {
    humanPageNumber,
    nextPagePath,
    numberOfPages,
    previousPagePath,
    limit,
  } = paginationData;
  return (
    <section>
      <TitleContainer>
        <Title>{title}</Title>
      </TitleContainer>
      <StyledCategories categories={categories} paginatePath={paginatePath} />
      <StyledContainer>
        <ArticlesList>
          {articles.map((item) => (
            <ArticleItem key={item.id} data={item} />
          ))}
        </ArticlesList>
        <Pagination
          pageNeighbours={1}
          currentPage={humanPageNumber}
          nextPagePath={nextPagePath}
          numberOfPages={numberOfPages}
          previousPagePath={previousPagePath}
          pageSize={limit}
          pathPrefix="/articles"
        />
      </StyledContainer>
    </section>
  );
};

const StyledCategories = styled(Categories)`
  max-width: 1440px;
  margin: 0 auto;
  margin-bottom: 20px;
  @media (max-width: 600px) {
    margin-bottom: 32px;
  }
`;

const TitleContainer = styled(Container)`
  padding-top: 20px;
  margin-bottom: 20px;
`;

const StyledContainer = styled(Container)`
  padding-bottom: 100px;
`;

const Title = styled.h2`
  ${TYPOGRAPHY.title2SemiBold48};
  color: ${COLORS.black1};
  @media (max-width: 600px) {
    ${TYPOGRAPHY.title4Medium28};
    color: ${COLORS.black2};
  }
`;

const ArticlesList = styled.div`
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  gap: 36px 24px;
  margin-bottom: 60px;
  :last-child {
    margin-bottom: 0;
  }
  @media (max-width: 768px) {
    display: block;
  }
`;

const ArticleItem = styled(ArticleCard)`
  grid-column: span 4;

  &:first-child,
  &:nth-child(7n - 7) {
    grid-column: 1 / -1;
  }

  &:nth-child(5n - 5),
  &:nth-child(6n - 6) {
    grid-column: auto / span 6;
  }
  @media (max-width: 1024px) {
    :nth-child(n) {
      grid-column: auto / span 6;
    }
  }
  @media (max-width: 768px) {
    margin-bottom: 32px;
    :last-child {
      margin-bottom: 0;
    }
  }
`;

export default Articles;
