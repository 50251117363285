import { Image } from '@/components/Image';
import { TagSection } from '@/components/TagSection';
import { COLORS, TYPOGRAPHY } from '@/constants';
import { ArticleData } from '@/types';
import { UiLink } from '@/ui/components/UiLink';
import dayjs from 'dayjs';
import React, { FC } from 'react';
import styled from 'styled-components';

interface ArticleCardProps {
  className?: string;
  data: ArticleData;
}

const ArticleCard: FC<ArticleCardProps> = ({ data, className }) => {
  return (
    <Root className={className}>
      <Wrapper>
        <ImageWrap>
          <StyledImage src={data.poster.url} alt={data.title} />
        </ImageWrap>
        <Content>
          <ContentHead>
            {data.category && (
              <TagSection text={data.category.category} size="small" />
            )}
            <DatePublication>
              {dayjs(data.created_at).format('DD MMM YYYY')}
            </DatePublication>
          </ContentHead>
          <Title>{data.title}</Title>
          <Excerption>{data.excerption}</Excerption>
          <Link text="Read full article" link={`/article/${data.strapiId}`} />
        </Content>
      </Wrapper>
    </Root>
  );
};

const StyledImage = styled.img``;

const Wrapper = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
`;

const ImageWrap = styled.div`
  overflow: hidden;
  border-radius: 10px;
  position: relative;
  z-index: 0;
  margin-bottom: 24px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
`;

const ContentHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
`;

const DatePublication = styled.p`
  ${TYPOGRAPHY.captionRegular14};
  color: ${COLORS.grayDarker};
`;

const Title = styled.h3`
  ${TYPOGRAPHY.body3Semibold20};
  color: ${COLORS.black1};
  margin-bottom: 20px;
`;

const Excerption = styled.p`
  ${TYPOGRAPHY.body2Light16};
  color: ${COLORS.grayDarker2};
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  margin-bottom: 20px;
`;

const Link = styled(UiLink)`
  width: 100%;
  margin-top: auto;
`;

const Root = styled.article`
  background-color: ${COLORS.lightGray4};
  border-radius: 20px;
  display: flex;

  :first-child,
  :nth-child(7n - 7) {
    ${Wrapper} {
      flex-direction: row;
    }
    ${ImageWrap} {
      margin-bottom: 0;
      margin-right: 24px;
    }
    ${StyledImage} {
      height: 100%;
      object-fit: cover;
    }

    ${Content} {
      max-width: 476px;
    }
    ${Title} {
      ${TYPOGRAPHY.title3SemiBold32};
    }
    ${Excerption} {
      -webkit-line-clamp: 5;
    }
  }
  :nth-child(5n - 5),
  :nth-child(6n - 6) {
    ${Title} {
      ${TYPOGRAPHY.title4Medium28};
    }
    ${Excerption} {
      -webkit-line-clamp: 2;
    }
  }

  @media (max-width: 1024px) {
    :nth-child(n) {
      ${Wrapper} {
        flex-direction: column;
      }
      ${ImageWrap} {
        margin-bottom: 24px;
        margin-right: 0;
      }
      ${Title} {
        ${TYPOGRAPHY.title4Medium28};
      }
      ${Excerption} {
        -webkit-line-clamp: 3;
      }
      ${Content} {
        max-width: none;
      }
    }
  }
  @media (max-width: 768px) {
    :nth-child(n) {
      ${Title} {
        ${TYPOGRAPHY.body1SemiBold18};
      }
    }
  }
`;

export default ArticleCard;
