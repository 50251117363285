import { COLORS, TYPOGRAPHY } from '@/constants';
import { usePagination, DOTS } from '@/hooks/usePagination';
import { ArrowLink } from '@/ui/components/ArrowLink';
import React, { FC } from 'react';
import styled from 'styled-components';
import { CustomLink } from '../CustomLink';

interface PaginationProps {
  currentPage: number;
  pageNeighbours: number;
  numberOfPages: number;
  nextPagePath: string;
  previousPagePath: string;
  pageSize: number;
  pathPrefix: string;
}

const Pagination: FC<PaginationProps> = ({
  numberOfPages,
  currentPage,
  nextPagePath,
  previousPagePath,
  pageNeighbours,
  pageSize,
  pathPrefix,
}) => {
  const paginationRange = usePagination({
    numberOfPages,
    pageSize,
    pageNeighbours,
    currentPage,
  });

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  return (
    <Root>
      <NavigationList>
        {previousPagePath && (
          <PrevButton withoutLocale={true} to={previousPagePath} />
        )}
        {paginationRange.map((pageNumber, index) => {
          if (pageNumber === DOTS) {
            return (
              <NavigationLink key={index} as="li" className="dots">
                &#8230;
              </NavigationLink>
            );
          }

          return (
            <NavigationLink
              to={pageNumber === 1 ? pathPrefix : `${pathPrefix}/${pageNumber}`}
              key={index}
              className={pageNumber === currentPage ? 'active' : ''}
            >
              {pageNumber}
            </NavigationLink>
          );
        })}

        {nextPagePath && (
          <NavigationButton withoutLocale={true} to={nextPagePath} />
        )}
      </NavigationList>
    </Root>
  );
};

const Root = styled.div`
  display: flex;
  justify-content: center;
`;

const NavigationList = styled.div`
  display: flex;
  align-items: center;
`;

const NavigationButton = styled(ArrowLink)`
  background-color: transparent;
  transition: background-color 0.3s ease;
  outline: none;
  :hover,
  :focus {
    background-color: ${COLORS.lightGray4};
  }
  @media (max-width: 768px) {
    width: 35px;
    height: 35px;
    border-radius: 8px;
  }
  @media (max-width: 374px) {
    width: 30px;
    height: 30px;
  }
`;

const PrevButton = styled(NavigationButton)`
  transform: rotate(180deg);
`;

const NavigationLink = styled(CustomLink)`
  display: flex;
  align-items: center;
  justify-content: center;
  ${TYPOGRAPHY.body1Medium18};
  color: ${COLORS.purple};
  width: 56px;
  height: 56px;
  border-radius: 16px;
  transition: background-color 0.3s ease;
  outline: none;
  &.active,
  :hover:not(.dots),
  :focus:not(.dots) {
    background-color: ${COLORS.lightGray2};
  }
  @media (max-width: 768px) {
    width: 35px;
    height: 35px;
    border-radius: 8px;
    ${TYPOGRAPHY.body2Medium16};
  }
  @media (max-width: 374px) {
    width: 30px;
    height: 30px;
  }
`;

export default Pagination;
