import React from 'react';
import MainLayout from '@/layouts/MainLayout';
import { Seo } from '@/components/Seo';
import { MainHeading } from '@/components/MainHeading';
import { graphql } from 'gatsby';
import { Articles } from '@/pageComponents/BlogPage/Articles';

const BlogCategory = ({ data, pageContext }) => {
  const { seo, title, locale } = pageContext.data;
  const articles = data.allStrapiArticles.nodes;
  const categories = data.allStrapiCategories.nodes;
  const {
    humanPageNumber,
    nextPagePath,
    numberOfPages,
    paginatePath,
    previousPagePath,
    limit,
  } = pageContext;

  const paginationData = {
    humanPageNumber,
    nextPagePath,
    numberOfPages,
    previousPagePath,
    limit,
  };
  return (
    <MainLayout locale={locale}>
      <Seo data={seo} />
      <MainHeading text={seo.mainHeading} />

      <Articles
        categories={categories}
        paginatePath={paginatePath}
        articles={articles}
        title={title}
        paginationData={paginationData}
      />
    </MainLayout>
  );
};

export const pageQuery = graphql`
  query ($skip: Int!, $limit: Int!, $locale: String, $category: String) {
    allStrapiArticles(
      filter: {
        category: { category: { eq: $category } }
        locale: { eq: $locale }
      }
      skip: $skip
      limit: $limit
      sort: { fields: [created_at], order: DESC }
    ) {
      nodes {
        seo {
          keywords
          mainHeading
          metaDescription
          metaTitle
          sharedImage {
            url
          }
        }
        id
        strapiId
        title
        content
        locale
        created_at
        excerption
        poster {
          url
          localFile {
            childImageSharp {
              gatsbyImageData
            }
            extension
            publicURL
          }
        }
        category {
          category
        }
      }
    }

    allStrapiCategories(filter: { locale: { eq: $locale } }) {
      nodes {
        locale
        category
      }
    }
  }
`;

export default BlogCategory;
