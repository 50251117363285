import { CustomLink } from '@/components/CustomLink';
import { COLORS } from '@/constants';
import { Arrow } from '@/ui/iconComponents/Arrow';
import { GatsbyLinkProps } from 'gatsby';
import React, { FC } from 'react';
import styled from 'styled-components';

interface ArrowLinkProps extends Omit<GatsbyLinkProps<{}>, 'ref'> {
  className?: string;
  withoutLocale?: boolean;
}

const ArrowLink: FC<ArrowLinkProps> = (props) => {
  return (
    <Root {...props}>
      <Arrow />
    </Root>
  );
};

const Root = styled(CustomLink)`
  width: 56px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${COLORS.lightGray4};
  border-radius: 16px;
`;

export default ArrowLink;
