import React, { FC } from 'react';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FreeMode } from 'swiper';
import { CustomLink } from '@/components/CustomLink';
import { ContentCell } from '@/components/ContentCell';
import { CategoryData } from '../../Articles';
import { COLORS, TYPOGRAPHY } from '@/constants';
import 'swiper/css';
import 'swiper/css/free-mode';

interface CategoriesProps {
  categories: CategoryData[];
  className?: string;
  paginatePath: string;
}

const Categories: FC<CategoriesProps> = ({
  categories,
  paginatePath,
  className,
}) => {
  return (
    <Root className={className}>
      <CategoriesList>
        <StyledCustomLink
          to="/articles/"
          className={
            paginatePath.includes('/articles') &&
            !paginatePath.includes('category')
              ? 'active'
              : ''
          }
        >
          <StyledContentCell>All</StyledContentCell>
        </StyledCustomLink>
        {categories.map((item) => {
          return (
            <StyledCustomLink
              key={item.category}
              to={`/articles/category/${item.category.toLowerCase()}/`}
              className={
                paginatePath.includes(item.category.toLowerCase())
                  ? 'active'
                  : ''
              }
            >
              <StyledContentCell>{item.category}</StyledContentCell>
            </StyledCustomLink>
          );
        })}
      </CategoriesList>
      <SliderWrap>
        <Swiper
          spaceBetween={20}
          slidesPerView="auto"
          freeMode={true}
          modules={[FreeMode]}
          breakpoints={{
            200: {
              slidesOffsetBefore: 16,
              slidesOffsetAfter: 16,
            },
            769: {
              slidesOffsetBefore: 32,
              slidesOffsetAfter: 32,
            },
            1025: {
              slidesOffsetBefore: 50,
              slidesOffsetAfter: 50,
              touchMoveStopPropagation: true,
            },
            1301: {
              slidesOffsetBefore: 108,
              slidesOffsetAfter: 108,
              touchMoveStopPropagation: true,
            },
          }}
        >
          <SwiperSlide>
            <StyledCustomLink
              to="/articles/"
              className={
                paginatePath.includes('/articles') &&
                !paginatePath.includes('category')
                  ? 'active'
                  : ''
              }
            >
              <StyledContentCell>All</StyledContentCell>
            </StyledCustomLink>
          </SwiperSlide>
          {categories.map((item) => {
            return (
              <SwiperSlide key={item.category}>
                <StyledCustomLink
                  to={`/articles/category/${item.category.toLowerCase()}/`}
                  className={
                    paginatePath.includes(item.category.toLowerCase())
                      ? 'active'
                      : ''
                  }
                >
                  <StyledContentCell>{item.category}</StyledContentCell>
                </StyledCustomLink>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </SliderWrap>
    </Root>
  );
};

const Root = styled.div`
  .swiper-slide {
    width: auto;
  }
`;

const CategoriesList = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-left: 108px;
  padding-right: 108px;
  @media (max-width: 1300px) {
    padding-left: 50px;
    padding-right: 50px;
  }
  @media (max-width: 1024px) {
    display: none;
  }
`;

const StyledContentCell = styled(ContentCell)`
  padding: 12px 40px;
  ${TYPOGRAPHY.body2Regular16};
  transition: background-color 0.3s ease;
`;

const StyledCustomLink = styled(CustomLink)`
  margin-right: 20px;
  margin-bottom: 20px;
  :hover {
    ${StyledContentCell} {
      background-color: ${COLORS.lightGray};
    }
  }
  &.active {
    pointer-events: none;
    ${StyledContentCell} {
      background-color: ${COLORS.purple};
      color: ${COLORS.white};
    }
  }
  @media (max-width: 1024px) {
    margin-right: 0;
    margin-bottom: 0;
  }
`;

const SliderWrap = styled.div`
  display: none;
  @media (max-width: 1024px) {
    display: block;
  }
`;

export default Categories;
