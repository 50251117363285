import { useMemo } from 'react';

export const DOTS = '...';

const range = (start: number, end: number) => {
  let length = end - start + 1;
  return Array.from({ length }, (_, idx) => idx + start);
};

interface usePaginationProps {
  pageSize: number;
  pageNeighbours: number;
  currentPage: number;
  numberOfPages: number;
}

export const usePagination = ({
  pageSize,
  pageNeighbours = 1,
  currentPage,
  numberOfPages,
}: usePaginationProps) => {
  const paginationRange = useMemo(() => {
    const totalPageNumbers = pageNeighbours + 5;

    if (totalPageNumbers >= numberOfPages) {
      return range(1, numberOfPages);
    }

    const leftSiblingIndex = Math.max(currentPage - pageNeighbours, 1);
    const rightSiblingIndex = Math.min(
      currentPage + pageNeighbours,
      numberOfPages,
    );

    const shouldShowLeftDots = leftSiblingIndex > 2;
    const shouldShowRightDots = rightSiblingIndex < numberOfPages - 2;

    const firstPageIndex = 1;
    const lastPageIndex = numberOfPages;

    if (!shouldShowLeftDots && shouldShowRightDots) {
      let leftItemCount = 3 + 2 * pageNeighbours;
      let leftRange = range(1, leftItemCount);

      return [...leftRange, DOTS, numberOfPages];
    }

    if (shouldShowLeftDots && !shouldShowRightDots) {
      let rightItemCount = 3 + 2 * pageNeighbours;
      let rightRange = range(numberOfPages - rightItemCount + 1, numberOfPages);
      return [firstPageIndex, DOTS, ...rightRange];
    }

    if (shouldShowLeftDots && shouldShowRightDots) {
      let middleRange = range(leftSiblingIndex, rightSiblingIndex);
      return [firstPageIndex, DOTS, ...middleRange, DOTS, lastPageIndex];
    }
  }, [numberOfPages, pageSize, pageNeighbours, currentPage]);

  return paginationRange;
};
