import { COLORS, TYPOGRAPHY } from '@/constants';
import React, { FC } from 'react';
import styled from 'styled-components';

type SizeValues = 'small' | 'normal';

interface TagSectionProps {
  text: string;
  size?: SizeValues;
  className?: string;
}

const TagSection: FC<TagSectionProps> = ({
  text,
  className,
  size = 'normal',
}) => {
  return (
    <Root className={className} $size={size}>
      {text}
    </Root>
  );
};

const Root = styled.p<{ $size: SizeValues }>`
  display: inline-block;
  padding: ${({ $size }) => ($size === 'normal' ? '4px 20px' : '2px 20px')};

  background-color: rgba(54, 37, 238, 0.1);
  border-radius: ${({ $size }) => ($size === 'normal' ? '11px' : '6px')};
  ${({ $size }) =>
    $size === 'normal' ? TYPOGRAPHY.body3Regular20 : TYPOGRAPHY.body2Regular16}

  color: ${COLORS.purple};

  @media (max-width: 768px) {
    ${TYPOGRAPHY.body2Regular16};
    padding: 6px 25px;
  }
`;

export default TagSection;
